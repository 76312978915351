import DomainContextParams from '@/utils/types/DomainContextParams';

export const DEAL_GENERIC_PAGE_HEADER_FRAGMENT = `
  fragment dealGenericPageHeaderFragment on Deal {
    id
    uid
    name
    title
    __typename
    startTime
    endTime
    displayFileResource {
      schemaCode
      path
    }
    categoriesInContext(context: "${DomainContextParams.deal}") {
      uid
      name
    }
    _isRecommendedForMe
  }
`;
